@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  html {
    @apply h-full bg-gray-50;
  }
  body {
    @apply h-full overflow-hidden;
  }
  #root {
    @apply h-screen;
  }
  button:focus {
    @apply outline-0
  }

  .tox.tox-tinymce {
    @apply border-1
  }
  .content a {
    @apply text-primary underline;
  }
}


.tox.tox-tinymce {
  border: 1px solid rgb(209, 213, 219);
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.05) 0 1px 2px 0;
}

@import "tinymce/skins/ui/oxide/skin.min.css";

